import "/src/css/prism.css";

require("prismjs/plugins/command-line/prism-command-line.css");

// prismjs/themes/prism-coy.css
// prismjs/themes/prism-coy.min.css
// prismjs/themes/prism-dark.css
// prismjs/themes/prism-dark.min.css
// prismjs/themes/prism-funky.css
// prismjs/themes/prism-funky.min.css
// prismjs/themes/prism-okaidia.css
// prismjs/themes/prism-okaidia.min.css
// prismjs/themes/prism-solarizedlight.css
// prismjs/themes/prism-solarizedlight.min.css
// prismjs/themes/prism-tomorrow.css
// prismjs/themes/prism-tomorrow.min.css
// prismjs/themes/prism-twilight.css
// prismjs/themes/prism-twilight.min.css
// prismjs/themes/prism.css
// prismjs/themes/prism.min.css
